import React from "react";
import withLayout from "../layout";
import {Heading} from "../components/UI/Typography";
import {FormattedMessage, injectIntl} from "react-intl";
import Dash from "../components/UI/Dash";
import JobsReasonsParagraph from "../components/UI/JobsReasonsParagraph";
import DropBlurVioletBg from '../images/jobs/DropBlurVioletBg.svg';
import DropBg from '../components/UI/DropBg';
import SEO from "../components/SEO";
import FullWidthMessage from "../components/FullWidthMessage";
import dark from "../theme/dark";
import {ThemeProvider} from "styled-components";
import CollapsableTable from "../components/UI/CollapsableTable";
import Hero from "../components/Hero";
import srcVideo from "../images/jobs_hero_video.mp4";
import {Fade} from "react-reveal";

const JOBS = [
    {
        title: 'jobs.development',
        rows: [
            {
                title: 'jobs.fullstack_dev',
                type: 'jobs.senior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/full-stack-senior',
            },
            {
                title: 'jobs.frontend_dev',
                type: 'jobs.junior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/front-end-junior'
            },
            {
                title: 'jobs.frontend_dev',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/front-end-mid'
            },
            {
                title: 'jobs.backend_dev',
                type: 'jobs.junior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/back-end-junior'
            },
            {
                title: 'jobs.backend_dev',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/back-end-mid'
            },
        ]
    },
    {
        title: 'jobs.design',
        rows: [
            {
                title: 'jobs.uiux_designer',
                type: 'jobs.junior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/ui-ux-junior'
            },
            {
                title: 'jobs.uiux_designer',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/ui-ux-mid'
            },
        ]
    },
  /*    Don't delete the commented object below, it's just hidden
        If you're reactivating it, remove "_" from the name of each page in src/pages/job-detail/ folder
   {
        title: 'jobs.marketing',
        rows: [
            {
                title: 'jobs.smm',
                type: 'jobs.junior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/social-media-manager-junior'
            },
            {
                title: 'jobs.smm',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/social-media-manager-mid'
            },
            {
                title: 'jobs.marketing_strategist',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/marketing-strategist-mid'
            },
            {
                title: 'jobs.marketing_strategist',
                type: 'jobs.senior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/marketing-strategist-senior'
            },
            {
                title: 'jobs.advertising_manager',
                type: 'jobs.junior',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/advertising-manager-junior'
            },
            {
                title: 'jobs.advertising_manager',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/advertising-manager-mid'
            },
            {
                title: 'jobs.growth_hacker',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/growth-hacker-mid'
            },
            {
                title: 'jobs.content_designer',
                type: 'jobs.mid',
                city: 'jobs.frosinone_rome',
                link: '/job-detail/content-designer-mid'
            }
        ]
    }*/
];

const JobsPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'jobs.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'jobs.meta_title'})}
             description={intl.formatMessage({id: 'jobs.meta_description'})} keywords={keywords}
             lang={intl.locale}/>

        {/* first white section */}
        <ThemeProvider theme={dark} colors={dark ? dark.colors : null}>
            <Hero isVideo hasModalVideo srcVideo={srcVideo} thinChild={1} title={"jobs.title_thin"} subtitle={"jobs.title"} paragraph={"jobs.subtitle"}/>
        </ThemeProvider>
        <div className="section-padding-top section-padding-bottom">
            <div className="container">
                <Fade bottom delay={400}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label className={"special-label"}>
                                <FormattedMessage id={"jobs.why_kromin"}/>
                            </label>
                            <Heading level={"2"} thinChild={1}>
                                <FormattedMessage id={"jobs.title_one_thin"}/><br/>
                                <FormattedMessage id={"jobs.title_one"}/>
                            </Heading>
                            <Dash/>
                        </div>
                    </div>
                </Fade>
                <div className="row mt-5">
                    <Fade bottom delay={400}>
                        <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                            <JobsReasonsParagraph label={"01"} description={"jobs.text_one"}
                                                  title={"jobs.subtitle_one"}/>
                        </div>
                    </Fade>
                    <Fade bottom delay={600}>
                        <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                            <JobsReasonsParagraph label={"02"} description={"jobs.text_two"}
                                                  title={"jobs.subtitle_two"}/>
                        </div>
                    </Fade>
                    <Fade bottom delay={800}>
                        <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                            <JobsReasonsParagraph label={"03"} description={"jobs.text_three"}
                                                  title={"jobs.subtitle_three"}/>
                        </div>
                    </Fade>
                    <Fade bottom delay={1000}>
                        <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                            <JobsReasonsParagraph label={"04"} description={"jobs.text_four"}
                                                  title={"jobs.subtitle_four"}/>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>

        <div className="section-gray">
            <DropBg src={DropBlurVioletBg} top={"0%"} noOverflow={true}/>
            <div className="section-inner-padding">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-12">
                            <Heading level={"3"} className="my-0">
                                <FormattedMessage id={"jobs.title_two"}/>
                            </Heading>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-12"}>
                            <CollapsableTable groups={JOBS} intl={intl}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FullWidthMessage title={"jobs.banner_title"} text1={"jobs.banner_message"} intl={intl}
                          ctaLink={"/internships/"} ctaText={"jobs.banner_link"} blur={true}/>
    </>
}
const customProps = {
    localeKey: "jobs", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(JobsPage));
